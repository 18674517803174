import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { BlogsListItem } from '@vkph/common/types/blogs';
import { SearchParams } from '@vkph/common/types/models';
import { abstractStorageFactory, buildEndpointWithQueryParams } from '@vkph/common/utils';

export const getSearchBlogStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<BlogsListItem>,
    BlogsListItem[],
    BlogsListItem[],
    SearchParams
  >({
    endpointBuilder: ({ pageNumber, pageSize }) =>
      buildEndpointWithQueryParams(endpoints.asyncBlogs.list(), { pageNumber, pageSize }),
    defaultValue: [],
    dataBuilder: ({ searchString }) => ({ search: searchString }),
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
    requestMethod: 'post',
  });

  return { storage };
};
