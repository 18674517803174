import { UiList, UiSkeleton, UiSkeletonProps, UiEmpty, UiTag, UiRow } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { Tag } from '@vkph/common/types/models';
import { createArrayMock } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import { useTheme } from '@vkph/ui/providers/theme';

export interface Props extends Pick<UiSkeletonProps, 'loading'> {
  tags: Tag[];
  onSelect?: (tag: Tag) => void;
}

export const TagListModalFoundTags: FC<Props> = (props) => {
  const { tags = [], loading, onSelect } = props;
  const [{ variables: themeVariables }] = useTheme();
  const { spaceXS, spaceM } = useSpace();

  const tagListMock = useMemo(() => createArrayMock(20, (_, index) => index), []);
  const isEmpty = tags.length === 0;

  return (
    <>
      {loading && (
        <UiList
          split={false}
          grid={{ gutter: [spaceXS, spaceM] }}
          dataSource={tagListMock}
          renderItem={() => {
            return (
              <UiList.Item noStyle>
                <UiSkeleton loading width={94} height={20} />
              </UiList.Item>
            );
          }}
        />
      )}
      {!loading && isEmpty && (
        <UiRow justify="center">
          <UiEmpty.Feed
            emptyMessage={{ header: 'Ничего не найдено', description: 'Попробуйте ввести другой тег' }}
          />
        </UiRow>
      )}
      {!loading && !isEmpty && (
        <UiList
          split={false}
          grid={{ gutter: [0, spaceM] }}
          dataSource={tags}
          renderItem={(tag) => {
            return (
              <UiList.Item noStyle>
                <UiTag
                  title={tag.name}
                  color={themeVariables.colorBrand04}
                  textColor={themeVariables.colorTextPrimary}
                  {...(onSelect && { onClick: () => onSelect(tag) })}
                />
              </UiList.Item>
            );
          }}
        />
      )}
    </>
  );
};
