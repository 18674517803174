import { OrderSwitcher } from '@vkph/components';
import {
  UiButton,
  UiCol,
  UiDivider,
  UiDropdown,
  UiIcon,
  UiInput,
  UiItemType,
  UiList,
  UiMenuClickEventHandler,
  UiModal,
  UiRow,
  UiSpace,
  UiTag,
} from '@vkph/ui';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks/useAbstractStorage';
import {
  GlobalModalNames,
  GlobalModalsStorePayloads,
  closeGlobalModal,
} from '@vkph/common/store/global-modals';
import { getTagsListStorage } from '@vkph/common/store/tags';
import { OrderingParams } from '@vkph/common/types/api';
import { Tag } from '@vkph/common/types/models';
import { useSpace } from '@vkph/ui/hooks';
import { useTheme } from '@vkph/ui/providers/theme';
import CloseSVG from '@vkph/ui/svg/close.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { TagListModalFoundTags } from './found-tags/TagListModalFoundTags';

type Props = NonNullable<GlobalModalsStorePayloads[GlobalModalNames.TagList]['payload']>;

enum TagListOrderByType {
  Alphabet = 'alphabet',
  Popular = 'popular',
}

type TagListOrder = {
  title: string;
  type: TagListOrderByType;
  asc: OrderingParams;
  desc: OrderingParams;
};

const TAG_LIST_AVAILABLE_ORDERINGS: Record<TagListOrderByType, TagListOrder> = {
  [TagListOrderByType.Alphabet]: {
    title: 'По алфавиту',
    type: TagListOrderByType.Alphabet,
    asc: OrderingParams.NameAtAsc,
    desc: OrderingParams.NameAtDesc,
  },
  [TagListOrderByType.Popular]: {
    title: 'По популярности',
    type: TagListOrderByType.Popular,
    asc: OrderingParams.ObjectsCountAtAsc,
    desc: OrderingParams.ObjectsCountAtDesc,
  },
};

export const TagListModal: FC<Props> = (props) => {
  const { relations, onSelect, value = [] } = props;
  const hasOnSelect = Boolean(onSelect);

  const [searchText, setSearchText] = useState('');
  const [selectedTags, setSelectedTags] = useState<Tag[]>(hasOnSelect ? value : []);
  const [{ variables: themeVariables }] = useTheme();
  const { spaceM } = useSpace();

  const [orderByType, setOrderByType] = useState(TagListOrderByType.Alphabet);
  const [isOrderDesc, setIsOrderDesc] = useState(false);
  const orderingParams = useMemo<OrderingParams>(() => {
    const orderingInfo = TAG_LIST_AVAILABLE_ORDERINGS[orderByType];

    return isOrderDesc ? orderingInfo.desc : orderingInfo.asc;
  }, [orderByType, isOrderDesc]);

  const { storage: tagsListStorage } = useMemo(getTagsListStorage, []);
  const { data: tagsData, loading: isTagsPending } = useAbstractStorage(tagsListStorage, {
    autoFetchParams: {
      search: searchText,
      ordering: [orderingParams],
      ...(relations && { serviceObjects: relations }),
    },
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  const onClose = () => {
    closeGlobalModal(GlobalModalNames.TagList);
  };

  const onSubmit = () => {
    onSelect?.(selectedTags);
    onClose();
  };

  const onSearch = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, 500);

  const availableTags = useMemo(() => {
    if (selectedTags.length > 0) {
      const selectedTagIdsSet = new Set(selectedTags.map(({ id }) => id));

      return tagsData.filter((tag) => !selectedTagIdsSet.has(tag.id));
    }

    return tagsData;
  }, [tagsData, selectedTags]);

  const onTagSelect = (tag: Tag) => {
    setSelectedTags((tags) => [...tags, tag]);
  };

  const onTagDeselect = (deselectedTag: Tag) => {
    setSelectedTags((tags) => tags.filter((tag) => tag.id !== deselectedTag.id));
  };

  const orderByTypeDropdownMenu = useMemo<UiItemType[]>(() => {
    return Object.values(TAG_LIST_AVAILABLE_ORDERINGS).map(({ type, title }) => ({
      key: type,
      label: title,
    }));
  }, []);

  const onClickOrderByType: UiMenuClickEventHandler = ({ key }) => {
    return setOrderByType(key as TagListOrderByType);
  };

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Теги группы')} />
      </UiModal.Header>

      <UiModal.Content>
        <UiSpace style={{ padding: '20px 24px 24px' }} size={24} direction="vertical" full>
          <UiInput.Search size="large" placeholder="Поиск" onChange={onSearch} />

          <UiRow justify="space-between">
            <UiCol>
              {selectedTags.length > 0 && (
                <UiButton
                  type="link"
                  label="Очистить выбранное"
                  onClick={() => setSelectedTags([])}
                  icon={<UiIcon component={CloseSVG} width={20} height={20} />}
                />
              )}
            </UiCol>
            <UiCol>
              <UiSpace size={16} full>
                <OrderSwitcher value={isOrderDesc} onChange={setIsOrderDesc} />
                <UiDropdown
                  trigger={['click']}
                  menu={{ items: orderByTypeDropdownMenu, onClick: onClickOrderByType }}
                >
                  <UiButton type="link" label={TAG_LIST_AVAILABLE_ORDERINGS[orderByType].title} />
                </UiDropdown>
              </UiSpace>
            </UiCol>
          </UiRow>
        </UiSpace>

        <UiDivider emptyMargin />

        <UiSpace style={{ padding: '20px 24px 24px' }} size={20} direction="vertical" full>
          {selectedTags.length > 0 && (
            <UiList
              split={false}
              grid={{ gutter: [0, spaceM] }}
              dataSource={selectedTags}
              renderItem={(tag) => {
                return (
                  <UiList.Item noStyle>
                    <UiTag
                      style={{ paddingTop: 6 }}
                      title={tag.name}
                      color={themeVariables.colorBrand08}
                      textColor={themeVariables.colorTextPrimary}
                    >
                      <UiButton
                        type="link-secondary"
                        onClick={() => onTagDeselect(tag)}
                        icon={<UiIcon component={CloseSVG} width={20} height={20} />}
                      />
                    </UiTag>
                  </UiList.Item>
                );
              }}
            />
          )}

          <TagListModalFoundTags
            tags={availableTags}
            loading={isTagsPending}
            {...(hasOnSelect && { onSelect: onTagSelect })}
          />
        </UiSpace>
      </UiModal.Content>

      {hasOnSelect && (
        <UiModal.Footer>
          <UiModal.Footer.Buttons>
            <UiButton type="primary" size="large" label="Выбрать" onClick={onSubmit} />
            <UiButton type="tertiary" size="large" label="Отмена" onClick={onClose} />
          </UiModal.Footer.Buttons>
        </UiModal.Footer>
      )}
    </>
  );
};
